.render-currency-component {
  .render-currency-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .render-currency-colon {
    margin-right: 5px;
  }
  .render-currency-item-gap {
    margin-right: 5px;
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;