.print-content-wrapper {
  // padding: 0px 30px 30px 30px;
  // border: 1px solid #ccc;
  .print-header-wrapper {
    display: flex;
    align-items: center;
    .print-logo {
      margin-left: auto;
    }
  }
  thead {
    th {
      background: #f3f3f3;
    }
  }

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 2px 8px;
  }
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  // border: 1px solid darkgrey;
  // box-shadow: 5px 5px 5px 1px #ccc;
  // border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  padding: 10px;
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
