.form-builder-component {
  .main-title {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .sub-title {
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.inline-form-table {
  .main-title {
    font-weight: 700;
    font-size: 13px;
  }
  .sub-title {
    font-weight: 600;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;