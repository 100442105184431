.print-title-wrapper {
  display: flex;
  align-items: center;
  .print-title-title {
    font-size: 20px;
  }
  .print-title-description {
    font-size: 12px;
    margin-left: 7px;
    margin-top: 7px;
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;