@import url('@base-styles/variables.less');

.custom-sidebar-wrapper {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(#333, 0.2);
    border: 3px solid transparent;
    border-radius: 4px;
  }

  .ant-layout-sider {
    border-right: 1px solid @base-border-color !important;
    z-index: 1;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .logo-wrapper {
    height: 50px;
    padding: 10px;
    padding-top: 20px;
    position: fixed;
    z-index: 1;
    background: #fff;
    border-right: 1px solid @base-border-color !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &.collapsed {
      width: @base-width-sidebar-collapsed;
    }
    &.no-collapsed {
      width: @base-width-sidebar-not-collapsed;
    }
  }

  .ant-menu-root {
    margin-top: 60px;
  }

  .ant-menu {
    background: transparent !important;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0px solid #1890ff !important; //TODO change this for gift border right on active menu
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;