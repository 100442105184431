@import url('@base-styles/variables.less');

.custom-navbar-wrapper {
  .custom-layout-header {
    // overflow: auto;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    border-bottom: 1px solid @base-border-color !important;
    background-color: #fff;
    // background-color: #432158;
  }

  .navbar-custom-menu {
    // margin-left: 20px;
    width: 50%;
    height: 100%;
  }

  .no-sidebar {
    width: 100%;
  }

  .not-collapsed-header {
    width: calc(100% - @base-width-sidebar-not-collapsed);
  }

  .collapsed-header {
    width: calc(100% - @base-width-sidebar-collapsed);
  }

  .navbar-right-content {
    display: flex;
    align-items: center;
    margin-left: auto !important;

    .navbar-right-item {
      margin-left: 25px;
      display: flex;
      align-items: center;

      .custom-icon-item {
        font-size: 14px;
      }

      .custom-avatar {
        border: 1px solid @base-border-color !important;
      }
    }
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;