@import url('@base-styles/variables.less');

.react-select-custom-prefix__control {
  border-radius: 2px !important;
  padding: 0 !important;
  margin: 0;
  &:hover {
    border-color: @base-border-color-form !important; // FIXME sesuaikan color dengan component antd
  }
}

.react-select-custom-prefix__value-container {
  display: flex !important;
}

// .react-select-custom-prefix__input-container {
//   position: absolute !important;
// }

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;