.print-signature-wrapper {
  margin-top: auto;
  .print-signature-item {
    display: flex;
    align-items: center;
    justify-content: center;

    .print-signature-title {
      font-weight: bold;
    }
    .print-signature-name {
      margin-top: 50px;
    }
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;