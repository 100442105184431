@import url('@base-styles/variables.less');

.form-generator-component {
  width: 100%;
  .form-generator-sidebar {
    .ant-layout-sider {
      border-right: 1px solid @base-border-color !important;
      overflow: auto;
      max-height: 70vh;
      position: fixed;
      max-width: 204px !important;
      min-width: 204px !important;
    }
    .field-item-wrapper {
      padding: 10px;
      .field-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        height: 35px;
        padding: 5px;
        background: #fff;
        border: 1px dashed @base-border-color !important;
        .field-item-icon {
          padding: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  .form-rendering-wrapper {
    width: 100%;
    display: flex;
    overflow: auto;
    // justify-content: center;
    margin-left: 214px;
    margin-top: 10px;
    .form-rendering-droppable-area {
      background: #eee;
      border: 1px dashed #ccc;
      width: 700px;
      min-height: 100px;
      overflow: hidden;
      margin-bottom: 20px;
      padding: 20px;

      .form-rendering-item {
        border: 1px solid @base-border-color !important;
        margin-bottom: 5px;

        .ant-card-body {
          padding: 0px;
          padding-bottom: 0px !important;
        }
        .form-rendering-item-action {
          padding: 0;
          padding-top: 6px;
        }
        .ant-divider-horizontal {
          margin: 0px !important;
        }
        .form-rendering-item-body {
          padding: 12px;
        }
      }
    }
  }
  .no-content-field {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;