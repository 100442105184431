@import '~antd/dist/antd.variable.less';
@import url('@base-styles/variables.less');

.custom-page-header {
  border-bottom: 1px solid @base-border-color !important;
  padding: 0px 24 !important;
  overflow: auto;
  position: fixed;
  top: @layout-header-height;
  z-index: 1;
  background: #fff !important;
  display: flex;
  align-items: center;

  .ant-page-header {
    padding: 5px 24px !important;
  }

  .prefix-content {
    display: flex;
    align-items: center;
  }

  .separator-content {
    height: 47px;
    border-left: 1px solid @base-border-color;
  }

  &.not-collapsed-header {
    width: calc(100% - @base-width-sidebar-not-collapsed);
  }

  &.collapsed-header {
    width: calc(100% - @base-width-sidebar-collapsed);
  }

  &.no-sidebar {
    width: 100%;
  }
}

@font-size-base: 10px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;@menu-collapsed-width: 65px;@layout-body-background: #ffffff;@layout-header-height: 55px;@page-header-heading-title: 14px;@page-header-heading-sub-title: 12px;@form-item-margin-bottom: 5px;@form-vertical-label-padding:  0 0 0px;